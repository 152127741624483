import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "privacy-policy" } }) {
        html
      }
    }
  `);

  return (
    <Layout>
      <h3 className="font-weight-bold spanborder">
        <span>Privacy Policy</span>
      </h3>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Layout>
  );
}
